.blur {
  filter: blur(5px);
  pointer-events: none;
}

.close-search {
  position: absolute;
  left: 70%; /* Adjusted to be right beside the input container */
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #272635;
}

.hamburger-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 2000;
  top: 60px;
  right: 10px;
  margin: 0 !important;
}

.hamburger-icon #menu__toggle {
  opacity: 0;
  position: absolute;
}

.header-container {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  background-color: #f7f8fb;
}

.header-right-Home {
  display: flex;
  align-items: center;
}

.genre-header-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5000 !important;
  padding: 1px;
}

.genre-header-wrapper .header-right-Home {
  background-color: rgba(39, 38, 53, 0.55) !important;
  color: white !important;
  border-radius: 10px;
  padding: 12px;
  margin: 0;
}

.headerTitles {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Lora", serif;
  color: #444;
}

.input-container-search {
  position: relative;
}

.link-style {
  text-decoration: none;
  color: black;
}

.link-style-header {
  text-decoration: none;
  color: black;
}

.genre-header-wrapper .link-style-header {
  color: white;
}

.main-content {
  margin-top: 50px; /* Adjust this to match the header height */
}

.menu__box {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%; /* Adjust width to fit screen */
  max-width: 507px; /* Maximum width */
  height: 100%;
  right: -100%; /* Off-screen initially */
  top: 0;
  padding: 30px 0;
  z-index: 9999 !important; /* Ensure it's above the overlay */
  background-color: #b1e5f2;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  transition: right 0.3s ease-in-out;
  overflow-y: auto; /* Allow scrolling */
  transform: translateZ(0);
  will-change: transform;
}

#menu__toggle:checked ~ .menu__box {
  right: 0;
}

.close__btn {
  display: none;
  position: absolute;
  cursor: pointer;
  font-size: 30px;
  color: #272635;
  z-index: 10000;
}

#menu__toggle:checked ~ .menu__box ~ .close__btn {
  display: block;
}

.menu__btn {
  margin-right: 10px; /* Adjust margin as needed */
  z-index: 2001;
}

.menu__item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px; /* Adjust height as needed */
  color: #272635;
  font-size: 30px;
  font-weight: 700;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.menu__item a {
  color: inherit; /* Inherit color from parent */
  text-decoration: none; /* Remove underline */
  width: 100%; /* Ensure full clickable area */
  height: 100%; /* Ensure full clickable area */
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu__item:hover {
  background-color: rgba(39, 38, 53, 0.7); /* #272635 with 70% opacity */
  color: #fff; /* Change text color on hover */
  text-decoration: none;
}

.menu__overlay {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999; /* Ensure it's below the menu but above the main content */
}

#menu__toggle:checked ~ .menu__overlay {
  visibility: visible;
}

#menu__toggle:checked ~ .main-content .content-blur-target {
  filter: blur(5px);
  pointer-events: none;
}

.search-bar-container {
  width: 100%;
  height: 185px;
  background: #c9cdd3;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
}

.searchForm1-search > button {
  height: 52px;
  width: 52px;
  border: 0;
  outline: 0;
  background-color: transparent;
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 84px;
  cursor: pointer;
}

.searchForm1-search > input:focus {
  outline: none;
}

.search-icon {
  margin-left: 10px;
  margin-right: 10px; /* Adjust margin as needed */
  cursor: pointer;
  height: 40px;
  width: 39px;
}

.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.subscribe-btn-Home {
  margin-right: 10px;
}

#menu__toggle {
  width: 50px !important;
}

@media only screen and (max-width: 480px) {
  .main-content {
    margin-top: 0;
  }

  .hamburger-icon {
    width: 10px;
    padding-left: 0;
    margin-left: 0;
  }

  .hamburger-icon label {
    font-size: 8px !important;
    top: 0;
    margin: 0 50px;
  }

  .subscribe-btn-Home {
    margin-right: 0;
    padding-right: 0;
  }

  .menu__btn {
    font-size: 8px !important;
    padding: 4px;
    width: 10px;
    margin-right: 0 !important;
    margin-left: 10px !important;
  }

  .svg-inline--fa {
    width: 25px !important;
    height: 25px !important;
  }

  .logoHome img {
    max-width: 100px; /* Scale logo down */
    height: auto;
  }

  .header-right-Home {
    display: flex;
    width: 140px;
    padding: 0 10px;
    top: 10px;
    margin-top: 20px;
  }

  .sign-in {
    display: none;
  }

  .search-icon {
    display: none;
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
  }

  .subscribe-btn-Home {
    font-size: 12px;
    padding-inline-start: 2px;
    width: 100px;
    height: 30px;
  }

  .menu__box {
    padding: 0 0;
  }

  .menu__box div {
    margin-top: 100px;
  }

  .menu__item {
    height: 60px;
    font-size: 25px;
    margin-top: 0px;
  }

  .close__btn {
    right: -40px;
  }
}
