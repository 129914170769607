@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
        .bodyPreference {
          font-family: "Roboto", sans-serif;
          background-color: #F7F8FB;
        }

        .logo-preference {
          display: block;
          top: 20px;
          left: 20px;
          width: 150px;
          height: 150px;
          mix-blend-mode: multiply;
        }
  
        .heading-preference {
          text-align: left;
          font-size: 30px;
          margin-top: 10px;
          margin-left: 100px;
        }
  
        .subheading-preference {
          text-align: left;
          font-size: 15px;
          margin-top: 5px;
          margin-left: 100px;
        }
  
        .options-preference {
          display: flex;
          flex-wrap: wrap; /* Wrap the options to new line */
          margin-top: 20px;
          margin-left: 100px;
        }
  
        .option-preference {
          width: 150px;
          height: 51px;
          margin: 10px;
          display: flex;
          align-items: center;
          flex-basis: calc(50% - 20px); /* Two columns with margin */
        }
  
        .option-preference input[type="checkbox"] {
          width: 51px;
          height: 51px;
          margin-right: 5px;
          cursor: pointer;
          border-radius: 5px;
          background-color: #C9CDD3;
          border: none; /* Remove border */
          outline: none; /* Remove outline */
          -webkit-appearance: none; /* Remove default checkbox appearance in Webkit browsers */
          -moz-appearance: none; /* Remove default checkbox appearance in Firefox */
          appearance: none; /* Remove default checkbox appearance */
          position: relative;
      }
      
      .option-preference input[type="checkbox"]::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #C9CDD3;
          border-radius: 5px;
      }
      
      .option-preference input[type="checkbox"]:checked::after {
          content: "✔"; /* Unicode for check mark */
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 30px;
          color: #272635;
      }
  
        .option-preference label {
          margin-left: 10px;
          font-size: 20px;
          cursor: pointer;
        }
  
        .card-container-preference {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start; /* Ensure cards start from the left */
          margin-top: 30px;
          margin-left: 110px;
          margin-bottom: 100px;
          gap: 55px;
        }
        
        .card-preference {
          width: 300px; /* Set card width */
          height: 300px; /* Set card height */
          margin: 10px; /* Adjust margin between cards */
          perspective: 1000px;
          border-radius: 10px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          overflow: hidden; /* Ensure content does not overflow */
        }
        
        .card-inner-preference {
          position: relative;
          width: 100%;
          height: 100%;
          transition: transform 0.8s;
          transform-style: preserve-3d;
          cursor:pointer;
          object-fit: contain;
        }
        
        .card-preference:hover .card-inner-preference {
          transform: rotateY(180deg) scale(1.1); /* Rotate and slightly scale */
        }
        
        .card-front-preference,
        .card-back-preference {
          position: absolute;
          width: 100%;
          height: 100%;
          backface-visibility: hidden;
          border-radius: 10px;
        }
        
        .card-front-preference {
          background-size: cover; /* Ensure image covers the entire card */
          background-repeat: no-repeat; /* Prevent image from repeating */
        }
        
        .card-back-preference {
          background: white;
          transform: rotateY(180deg);
          text-align: center;
          padding: 15px;
        }
        
        .card-preference.selected {
          border: 5px solid #b1e5f2; /* Border when selected */
        }
        
        @media (max-width: 800px) {
          .card-preference {
            width: calc(25% - 20px); /* Adjust for responsive design */
            height: auto; /* Allow cards to adjust height based on content */
          }
        }
        
        
  
  
  
        /* -------------Progress Bar------------------- */
        .container-preference {
          /* width: 1550px; */
          margin: auto;
          text-align: center;
          padding-bottom: 100px;
          padding-top: 35px;
        }

        .progressbar-preference{
            display: flex;
            justify-content: center;
            margin-top: -100px; 
            margin-bottom: 100px;
            width: 715px;
            margin-left: 450px; 
        }
        .progressbar-preference li {
          list-style-type: none;
          width: calc(25% - 30px);
          float: left;
          position: relative;
          text-align: center;
          color: #7d7d7d;
        }
        .progressbar-preference li:before {
          width: 39px;
          height: 39px;
          content: "";
          line-height: 35px;
          display: block;
          /* text-align: top; */
          margin: 0 auto 10px auto;
          border-radius: 50%;
        }
        .progressbar-preference li.done:before {
          content: "✓";
          color: #272635;
          font-size: 30px;
        }
  
        .progressbar-preference li span {
          position: absolute;
          color:#272635;
          top: -30px; /* Adjust vertical position */
          left: 50%; /* Center horizontally */
          transform: translateX(-50%); /* Center horizontally */
          font-size: 12px;
          font-weight: 400;
        }
  
        .progressbar-preference li:after {
          width: 100%;
          height: 3px;
          content: "";
          position: absolute;
          background-color: #7d7d7d;
          top: 15px;
          left: -50%;
          z-index: -1;
        }
        .progressbar-preference li:first-child:after {
          content: none;
        }
        .progressbar-preference li.active {
          color: rgb(0, 0, 0);
        }
        .progressbar-preference li.active:before {
          background-color: #A6A6A8;
    border: 3px solid #b1e5f2;
        }
        .progressbar-preference li.active + li:after {
          background-color: #b1e5f2;
        }
        .progressbar-preference li.done:before {
          border-color: #272635;
          background-color: #b1e5f2;
        }
        .progressbar-preference li.done + li:after {
          background-color: #A6A6A8;
        }
        .progressbar-preference li.inactive:before {
          border-color: #7d7d7d;
          background-color: #A6A6A8;
        }
        .progressbar-preference li.inactive + li:after {
          background-color: #B1E5F2;
        }
  
  /* Define circular button styles */
  .button-container {
    bottom: 150px;
    margin-left: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .next-button {
    width: 60px;
    height: 60px;
    background-color: #272635;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #b1e5f2;
    font-size: 45px;
    text-decoration: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .skip-text {
    margin-top: 10px; /* Adjust this value as needed for spacing */
    color: #272635;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
  }

  @media (max-width: 1205px) {

    .progressbar-preference{
      display: flex;
      justify-content: center;
      margin-top: -100px; 
      margin-bottom: 100px;
      width: 715px;
      margin-left: 195px; 
  }

  }