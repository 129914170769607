@font-face {
  font-family: 'Roboto-Light';
  src: url('../../../public/web fonts/roboto_medium_macroman/Roboto-Medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.page-container-login {
  display: flex;
  font-family: 'Roboto-Light';
  box-sizing: border-box;
}

.left-container-login {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #b1e5f2;
  position: relative;
  height: 100vh;
  border-right: 1px solid #272635;
}

.right-container-login {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F7F8FB;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.logo-login {
  width: 350px;
  height: 350px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  mix-blend-mode: multiply;
}

.form-login {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  box-sizing: border-box;
}

.form-login h1 {
  margin-bottom: 20px;
}

.input-login {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.login-btn {
  background-color: #b1e5f2;
  color: #272635;
  padding: 15px 20px; 
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 289px;
  border-radius: 10px;
  font-size: 28px;
  font-family: 'Roboto-Light';
}

.login-btn:hover {
  opacity: 0.8;
}

.separator-login {
  display: flex;
  align-items: center;
  margin: 10px 10px; /* Adjust spacing between buttons and separator */
  margin-top: 40px;
}

.separator-login hr {
  width: 241px; /* Adjust width of the lines */
  height: 1px; /* Adjust height of the lines */
  border: none; /* Remove default border style */
  background-color: #272635; /* Color of the lines */
  margin: 0 15px; /* Spacing between lines and "OR" text */
}

.separator-login span {
  margin: 0 auto; /* Center "OR" text horizontally */
  font-size: 36px;
  font-weight: 700;
}

.picture {
  width: 100%; /* Make the picture fill the container width */
  height: 35%; /* Maintain aspect ratio */
  margin-top: auto;
  border-top: 1px solid #272635;
}

:root {
  --line-border-fill: #3498db;
  --line-border-empty: #e0e0e0;
}

.container {
  margin-top: 15px;
  text-align: center;
}

.progress-container::before {
  content: "";
  background: var(--line-border-empty);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 100%;
  z-index: -1;
}

.progress-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 30px;
  max-width: 100%;
  width: 350px;
}

.progress {
  background: var(--line-border-fill);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 0%;
  z-index: -1;
  transition: 0.4s ease;
}

.circle {
  background: #fff;
  color: #999;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--line-border-empty);
  transition: 0.4s ease;
}

.circle.active {
  border-color: var(--line-border-fill);
}

.btn {
  background-color: var(--line-border-fill);
  color: #fff;
  cursor: pointer;
  font-family: inherit;
  border: 0;
  border-radius: 6px;
  padding: 8px 30px;
  margin: 5px;
  font-size: 14px;
}

.btn:active {
  transform: scale(0.98);
}

.btn:focus {
  outline: 0;
}

.btn:disabled {
  background-color: var(--line-border-empty);
  cursor: not-allowed;
}

/* Container for each input */
.input-container-login {
  display: flex; /* Use flexbox */
  flex-direction: column; /* Align items vertically */
  margin-bottom: 10px; /* Add some space between input containers */
  font-size: 15px;
  font-weight: bold;
}

/* Label styling */
.input-container-login label {
  align-self: flex-start; /* Do not allow labels to grow or shrink */
  margin-right: 10px; /* Add some space between label and input */
  font-size: 25px;
  font-weight: 500;
}

/* Input styling */
.input-container-login input {
  width: 100%; 
  background-color: #c9cdd3;
  border-radius: 10px;
  font-size: 16px;
  height: 48px;
}

.remember-me-login {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.remember-me-login .input-login {
  margin-left: 10px;
  width: 20px !important;
}

.remember-me-login p {
  margin-left: 5px;
}

.remember-me-login .linkClass {
  margin-left: auto;
  margin-right: 10px;
  right: 10px;
  font-weight: 400;
  text-decoration: none;
}

.separator-login span {
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  .page-container-login {
    height: 70vh !important;
  }

  .left-container-login {
    height: 70vh !important;
  }

  .right-container-login {
    height: 70vh !important;
  }

  .logo-login {
    width: 200px;
    height: 200px;
  }

  .form-login {
    width: 80%;
  }

  .separator-login hr {
    width: 100px;
  }
}

@media only screen and (max-width: 480px) {
  .page-container-login{
    height: 475px !important;
  }

  .left-container-login {
    display: none;
  }

  .right-container-login {
    height: 475px !important;
  }

  .form-login {
    height: 475px !important;
  }

  .form-login h1 {
    font-size: 24px;
    font-weight: 700;
  }

  .form-login {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers items horizontally */
    justify-content: center; /* Centers items vertically */
    margin: 0 auto; /* Centers horizontally if not using flexbox */
    box-sizing: border-box; /* Ensures padding is included in dimensions */
  }

  .input-container-login label {
    font-size: 18px;
    font-weight: 500;
    width: 240px;
    text-align: left;
  }

  .input-container-login {
    display: flex;
    flex-direction: column; /* Stack inputs vertically */
    align-items: center; /* Align inputs horizontally */
    width: 100%; /* Optional: Adjust to ensure it doesn't shrink */
  }

  .input-container-login input {
    width: 240px; /* Adjust width to your preference */
    height: 32px;
  }

  .separator-login hr {
    width: 90px;
  }

  .remember-me-login p {
    font-size: 14px !important;
  }

  .remember-me-login .linkClass {
    font-size: 14px;
  }

  .login-btn {
    background-color: #b1e5f2;
    color: #272635;
    padding: 10px 15px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 115px;
    border-radius: 10px;
    font-size: 18px;
    /* font-weight: 700; */
    font-family: 'Roboto-Light';
  }

  .separator-login {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .separator-login span {
    font-size: 14px;
  }

  .form-login p {
    font-size: 14px;
  }
}
