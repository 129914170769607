.category-hero {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.card-container-cat {
  position: relative;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-content-categoryList {
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  justify-content: center;
  margin-right: 200px;
  margin-left: 200px;
}

.card-categoryList {
  width: 400px;
  height: 400px;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.image-container-categoryList {
  position: relative;
  width: 100%;
  height: 100%;
}

.card-categoryList img {
  width: 100%;
  height: auto;
  display: block;
}

.card-content {
  padding: 10px;
}

.card-content h2 {
  font-size: 18px;
  margin: 0 0 10px 0;
}

.card-content p {
  font-size: 14px;
}

.text-overlay {
  position: absolute;
  bottom: 10%;
  left: 5%;
  font-size: 30px;
  font-weight: 700;
  background-color: rgba(39, 38, 53, 0.55) !important;
  color: white !important;
  border-radius: 10px;
  padding: 12px;
  margin: 0;
}

.filter-wrapper-category {
  position: relative;
  margin-left: auto;
  margin-right: 3rem;
}

.filter-btn-category {
  width: 160px;
  height: 50px;
  color: #b1e5f2;
  background-color: #272635;
  border: none;
  font-size: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 10px;
  margin: 0 !important;
}

.text-below-line {
  position: absolute;
  top: 778px; /* Adjust as needed for spacing below the line */
  left: 50%;
  width: 80%;
  transform: translateX(-50%);
  text-align: center;
  color: #272635; /* Adjust text color as needed */
  font-size: 20px;
  font-weight: 400;
}

.filter-dialog-category {
  width: 182px;
  height: 165px;
  border-radius: 10px;
  background-color: #a6a6a8;
  padding: 20px;
  position: absolute;
  right: 0;
  top: calc(100% + 5px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: 15%;
}

@media (max-width: 768px) {
  .text-overlay {
    font-size: 22px;
  }

  .filter-wrapper-category {
    margin-bottom: 20px;
  }

  .card-content-categoryList {
    flex-direction: column; /* Stack cards vertically */
    gap: 20px;
    margin: 10px;
  }

  .card-categoryList {
    width: 90%; /* Full width on mobile */
    margin: 0 auto; /* Center cards */
  }

  .text-below-line {
    top: 75%;
    font-size: 14px;
  }

  .pagination {
    gap: 5px;
  }

  .pagination button {
    width: 100px; /* Fixed button width */
    height: 40px;
  }
}

@media (max-width: 480px) {
  .filter-dialog-category {
    width: 120px;
    height: 105px;
    padding: 10px;
  }

  .filter-dialog-category h2 {
    font-size: 12px;
  }

  .text-overlay {
    font-size: 16px;
  }

  .filter-wrapper-category {
    margin-right: 0rem;
  }

  .filter-btn-category {
    margin: 0 !important;
    width: 80px !important;
    height: 35px !important;
    font-size: 12px !important;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .svg-inline--fa fa-filter img {
    font-size: 4px !important;
    height: 4px !important;
  }
}
