/* FAQ.css */
.faq-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.faq-responsive-image {
  max-width: 100%;
  height: auto;
}
