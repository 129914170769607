 /* ------------------------------------ */
    .profile-pic {
      color: transparent;
      transition: all 0.3s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: all 0.3s ease;
    }
    .profile-pic input {
      display: none;
    }
    .profile-pic img {
      position: absolute;
      object-fit: cover;
      width: 165px;
      height: 165px;
      box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
      border-radius: 100px;
      z-index: 0;
    }
    .profile-pic .-label {
      cursor: pointer;
      height: 165px;
      width: 165px;
    }
    .profile-pic:hover .-label {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 10000;
      color: #fafafa;
      transition: background-color 0.2s ease-in-out;
      border-radius: 100px;
      margin-bottom: 0;
    }
    .profile-pic span {
      display: inline-flex;
      padding: 0.2em;
      height: 2em;
    }
    body a:hover {
      text-decoration: none;
    }

    /* ------------------------------------ */
    body {
      background-color: #F7F8FB; /* Set background color for the entire page */
    }

    .container-profile {
      max-width: 100%;
      margin-top: 20px; /* Add margin to separate header from cards */
    }

    .header-profile {
      background-color: #F7F8FB;
      padding: 10px;
      display: flex;
      justify-content: space-between;
    }

    .logo-profile img {
      mix-blend-mode: multiply;
    }

    .cards-profile {
      position: relative;
      display: flex;
      justify-content: space-evenly;
      margin-top: 20px; /* Add margin to separate cards */
    }

    .left-card-profile,
    .right-card-profile {
      border-radius: 5%; /* Apply 5% border radius to both cards */
    }

    .left-card-profile {
      width: 449px;
      height: 535px;
      background-color: #b1e5f2;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 60px;
    }

    .user-info-profile {
      text-align: center;
    }

    .profile-pic {
      width: 150px;
      height: 150px;
      background-color: #ccc; /* Placeholder color */
      border-radius: 50%; /* Circular shape */
      margin: 20px auto; /* Center the profile picture */
      overflow: hidden; /* Hide overflow from circular shape */
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center; /* Center the icon vertically */
    }
    .input-container {
      position: relative;
    }

    #editableInput {
      width: 100%;
      height: auto;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 10px;
      resize: none; /* Prevent resizing */
      overflow-y: auto; /* Enable vertical scrollbar when needed */
      word-wrap: break-word; /* Allow text to wrap to a new line */
      background-color:  #E8E9F3;
      font-size: 14px;
      font-weight: 700;
      box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.25);;
    }

    .pencil-icon {
      position: absolute;
      bottom: 6px;
      right: 6px;
      cursor: pointer;
    }

    .right-card-profile {
      width: 827px; /* Adjust width to fill remaining space */
      min-height: auto;
      display: flex;
      flex-direction: column;
      /* justify-content: space-between; */
      /* background-color: #E8E9F3; */
      background: linear-gradient(to top, #e8e9f3 90%, #272635 10%);
      border-radius: 10px;
      margin-right: 60px;
      margin-bottom: 36px;
    }

    /* Profile Pic */

    .edit-icon {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-50%, -25%); /* Adjusted positioning for icon */
      background-color: #ffffff88; /* Semi-transparent background */
      border-radius: 50%;
      cursor: pointer;
      padding: 5px;
    }

    .edit-icon svg {
      fill: #333;
      width: 24px;
      height: 24px;
    }

    #profile-preview {
      width: 100%;
      height: 100%;
      object-fit: cover; /* Ensure image covers the entire circular area */
    }

    .edit-profile {
      font-size: 24px;
      margin-bottom: 10px;
      color: white;
      margin-left: 45px;
      margin-top: 10px;
    }

    /* Added for accessibility */
    .visually-hidden {
      position: absolute !important;
      clip: rect(0 0 0 0) !important;
      height: 1px !important;
      width: 1px !important;
      overflow: hidden !important;
      border: 0 !important;
      padding: 0 !important;
      margin: -1px !important;
    }
    .divider {
      border-color: white;
      margin-bottom: 20px;
    }

    .profile-sections {
      list-style-type: none;
      padding-bottom: 30px;
      padding-top: 10px;
      display: flex;
      color: white;
      font-size: 20px;
      gap: 24px;
    }

    /*  Form for User Info */

    /* .form-section-profile2 {
      margin-top: 20px;
      padding: 10px;
      
      color: #272635;
    } */

    .button {
      padding: 10px 20px;
      color: #b1e5f2;
      background-color: #272635;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      width: 50%;
    }

    .button:hover {
      background-color: #90cad7;
    }

    .save-button {
      padding: 10px 35px; /* Adjust padding for smaller size */
      background-color: #272635;
      color: #b1e5f2;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    .save-button:hover {
      background-color: #90cad7;
    }

    /* Side by Side form input */

    /* Asterick ** */

    #user-info-form {
      display: none; /* Hide the form initially */
    }

    .profile-sections li a {
      text-decoration: none; /* Remove underline */
      color: inherit; /* Inherit text color */
    }

    .profile-sections li a:hover {
      text-decoration: none; /* Remove underline on hover */
      color: inherit; /* Inherit text color on hover */
    }

    /* Cards CSS for author preferences */

    /* Genre Preferences */

    /* ---------------------------------------------- */

    .profile-button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      text-decoration: none;
      color: #e8e9f3; /* Default button color */
      transition: color 0.3s ease; /* Smooth transition for color change */
    }

    .profile-button:focus,
    .profile-button:hover,
    .profile-button.active {
      color: #b1e5f2; /* Change button color on hover/focus/when active */
    }
    /* --------------------------------------- */

    .hamburger-icon {
      font-size: 20px;
      cursor: pointer;
      margin-right: 20px;
    }

    /* Mobile menu styles */
    .mobile-menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: #b1e5f2;
      justify-content: center;
      align-items: center;
      z-index: 99;
      transition: opacity 0.3s ease-in-out;
      padding-top: 300px;
    }

    .mobile-menu.hidden {
      opacity: 0;
      pointer-events: none;
    }

    .mobile-menu-content {
      border-radius: 10px;
      text-align: center;
    }

    .menu-items {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 35px; /* Adjust font size as needed */
    }

    /* Background on menu item, clear bottom border (optional) */
    .menu-item {
      margin-bottom: 1rem;
      position: relative; /* Enable positioning of child element */
      overflow: hidden; /* Hide overflowing content */
      background-color: transparent; /* Set background for menu item */
      border-bottom: none; /* Clear potential default border (optional) */
    }

    .menu-item a {
      color: #272635;
      text-decoration: none;
      font-size: inherit; /* Inherit font size from .menu-items */
      transition: color 0.3s ease-in-out;
      width: 100%; /* Stretch link to full width */
      display: block; /* Make link take full height of menu item */
      padding: 1rem 0; /* Add some padding for visual separation */
      position: relative; /* Position for layering */
    }

    .menu-item a:hover {
      color: #f7f8fb; /* Hover text color */
      background-color: #272635;
    }

    /* Animated hover effect remains the same */
    .menu-item a:hover::after {
      content: "";
      position: absolute;
      top: 0;
      left: -100%; /* Start from left off-screen */
      width: 100%;
      height: 100%;
      background-color: #272635; /* Hover background color */
      transition: left 0.3s ease-in-out;
    }

    /* Close icon styles */
    .close-icon-container {
      position: absolute;
      top: 5rem;
      right: 2rem;
      cursor: pointer;
    }

    /* -------------------- */

    .social-icons {
      position: absolute;
      bottom: 1rem; /* Adjust distance from bottom */
      left: 50%; /* Center horizontally */
      transform: translateX(-50%); /* Center horizontally (alternative) */
      display: flex; /* Arrange icons horizontally */
      justify-content: center; /* Center icons horizontally */
      /* Add spacing between icons */
      gap: 50px; /* Adjust spacing between icons as needed */
    }

    .form-section-profile2 {
      /* Other styles */
      height: auto; /* Allow the height to adjust based on content */
      padding: 20px; /* Optional: Add padding for better spacing */
      transition: height 0.3s ease; /* Smooth transition if height changes */
    }

@media screen and (max-width: 768px) {
  .container-profile {
    max-width: 100%;
  }

  .cards-profile {
    flex-direction: column; /* Stack profile sections vertically */
    align-items: center;
    box-sizing: border-box;
  }

  .left-card-profile {
    width: 100%; /* Ensure profile sections fit the screen */
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
  }

  .right-card-profile {
    width: 100%; /* Ensure profile sections fit the screen */
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
  }

  .profile-sections {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    align-items: center;
    display: flex;
    gap: 10px; /* Add spacing between menu items */
    padding: 12px;
    background-color: #272635; /* Ensure contrast with text */
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 12px;
  }

  .edit-profile {
    font-size: 28px !important; /* Reduce font size */
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  }

  .profile-pic img {
    width: 120px;
    height: 120px;
  }

  input,
  textarea {
    width: 100% !important; /* Ensure input fields take full width within the container */
    max-width: 100% !important; /* Prevent overflow */
    box-sizing: border-box; /* Ensures padding doesn't add extra width */
  }

  .input-container {
    width: 100%;
    max-width: 100%;
    padding: 0 10px; /* Add slight padding on the sides */
  }

  .input-container textarea {
    width: 100%;
    font-size: 14px;
  }

  .menu-items {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    font-size: 28px;
  }

  .mobile-menu {
    padding-top: 150px; /* Reduce padding */
  }

  .profile-sections li {
    width: 100%;
    text-align: center;
  }

  .profile-button {
    display: block;
    width: 100%;
    font-size: 16px !important;
    padding: 8px;
    color: #ffffff !important; /* Force white text */
    background-color: transparent; /* Remove unwanted background */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Subtle border */
    border-radius: 5px;
  }

  .profile-button:hover,
  .profile-button:focus {
    background-color: rgba(255, 255, 255, 0.1); /* Light hover effect */
  }

  .save-button {
    width: auto !important;  /* Prevent unnecessary width */
    display: block; /* Make it take full width available */
    text-align: center;
    margin-right: auto;
  }

  .UserProfileForm label {
    font-size: 16px !important;
  }

  .UserProfileForm input {
    font-size: 16px !important;
    padding: 16px;
    height: 50px;
  }
}

@media screen and (max-width: 480px) {
  .container-profile {
    max-width: 100%;
    margin-top: 10px; /* Reduce margin */
    padding: 10px;
  }

  .cards-profile {
    flex-direction: column; /* Stack profile sections vertically */
    align-items: center;
    box-sizing: border-box;
  }

  .left-card-profile,
  .right-card-profile {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    border-radius: 0;
    padding: 15px 0;
  }

  .profile-sections {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    flex-direction: column; /* Stack profile options */
    text-align: center;
    align-items: center;
    display: flex;
    gap: 10px; /* Add spacing between menu items */
    padding: 12px;
    background-color: #272635; /* Ensure contrast with text */
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
  }

  .profile-button {
    font-size: 14px; /* Adjust button size for readability */
    width: 100% !important;
    max-width: 100% !important;
    box-sizing: border-box; /* Prevents width overflow */
  }

  .edit-profile {
    font-size: 20px; /* Reduce font size */
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  }

  .profile-pic img {
    width: 100px;
    height: 100px;
  }

  input,
  textarea {
    width: 100% !important; /* Ensure input fields take full width within the container */
    max-width: 100% !important; /* Prevent overflow */
    box-sizing: border-box; /* Ensures padding doesn't add extra width */
  }

  .input-container {
    width: 100%;
    max-width: 100%;
    padding: 0 10px; /* Add slight padding on the sides */
  }

  .input-container textarea {
    width: 100%;
    font-size: 14px;
    padding: 5px;
  }

  .mobile-menu {
    padding-top: 100px;
  }

  .menu-items {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    font-size: 24px;
  }

  .profile-pic .-label {
    height: 100px;
    width: 100px;
  }

  .profile-sections li {
    width: 100%;
    text-align: center;
  }

  .profile-button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    text-align: center;
    border-radius: 8px; /* Rounded corners */
    background-color: #272635; /* Adjust background to match */
    color: #ffffff !important; /* Force white text */
    transition: background 0.3s ease;
  }

  .profile-button:hover,
  .profile-button:focus {
    background-color: rgba(255, 255, 255, 0.1); /* Light hover effect */
  }

  .profile-button:hover {
    background-color: #3a3f4b;
  }

  .save-button {
    display: block; /* Make it take full width available */
    max-width: 250px; /* Ensures a reasonable button size */
    margin: 20px auto !important; /* Center it under the form */
    text-align: center;
    width: 100%;
    font-size: 14px;
  }

  .form-row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .input-container-UserProfile {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px; /* Adds spacing */
  }

  .input-container-UserProfile label {
    display: block;
    width: 100%;
    text-align: left;
  }

  .input-container-UserProfile input,
  .input-container-UserProfile select {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  .black-section {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0;
    background-color: #272635;
    border-radius: 0px;
  }
}
