.page-container-register {
  display: flex;
  box-sizing: border-box;
}

.left-container-register {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #b1e5f2;
  position: relative;
  height: 100vh;
  border-right: 1px solid #272635;
}

.right-container-register {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F7F8FB;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.logo-register {
  width: 350px;
  height: 350px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  mix-blend-mode: multiply;
}

.form-register {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  box-sizing: border-box;
}

.form-register h1{
  margin-bottom: 20px;
}

.form-register input {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.RegisterBtn {
  background-color: #b1e5f2;
  color: #272635;
  padding: 15px 20px; 
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 289px;
  border-radius: 10px;
  font-size: 28px;
  font-family: 'Roboto-Light';
}

.RegisterBtn:hover {
  opacity: 0.8;
}

.separator-register {
  display: flex;
  align-items: center;
  margin: 10px 10px; /* Adjust spacing between buttons and separator */
  margin-top: 40px;
}

.separator-register hr {
  width: 241px; /* Adjust width of the lines */
  height: 1px; /* Adjust height of the lines */
  border: none; /* Remove default border style */
  background-color: #272635; /* Color of the lines */
  margin: 0 15px; /* Spacing between lines and "OR" text */
}

.separator-register span {
  margin: 0 auto; /* Center "OR" text horizontally */
  font-size: 36px;
  font-weight: 700;
}

.button-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picture {
  width: 100%; /* Make the picture fill the container width */
  height: 35%; /* Maintain aspect ratio */
  margin-top: auto;
  border-top: 1px solid #272635;
}

/* Container for each input */
.input-container-register {
  display: flex; /* Use flexbox */
  flex-direction: column; /* Align items vertically */
  margin-bottom: 10px; /* Add some space between input containers */
  font-size: 15px;
  font-weight: bold;
}

/* Label styling */
.input-container-register label {
  align-self: flex-start; /* Do not allow labels to grow or shrink */
  margin-right: 10px; /* Add some space between label and input */
  font-size: 25px;
  font-weight: 500;
}

/* Input styling */
.input-container-register input {
  width: 100%; /* Allow input to grow to fill remaining space */
  background-color: #c9cdd3;
  border-radius: 10px;
  font-size: 16px;
  height: 48px;
}

.remember-me-register .input-register {
  margin-left: 5px;
  width: 20px !important;
}

.separator-register span {
  font-size: 20px !important;
}

@media only screen and (max-width: 768px) {
  .page-container-register {
    height: 70vh !important;
  }

  .left-container-register {
    height: 70vh !important;
  }

  .right-container-register {
    height: 70vh !important;
  }

  .logo-register {
    width: 200px;
    height: 200px;
  }

  .form-register {
    width: 80%;
  }
  
  .separator-register hr {
    width: 100px;
  }
}

@media only screen and (max-width: 480px) {
  .page-container-register{
    height: 560px !important;
  }

  .left-container-register{
    display: none;
  }

  .right-container-register {
    height: 560px !important;
  }

  .form-register {
    height: 560px !important;
  }

  .form-register h1{
    font-size: 24px;
    font-weight: 700;
  }

  .form-register {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers items horizontally */
    justify-content: center; /* Centers items vertically */
    margin: 0 auto; /* Centers horizontally if not using flexbox */
    box-sizing: border-box; /* Ensures padding is included in dimensions */
  }

  .input-container-register label{
    font-size: 18px;
    font-weight: 500;
    width: 240px;
    text-align: left;
  }

  .input-container-register {
    display: flex;
    flex-direction: column; /* Stack inputs vertically */
    align-items: center; /* Align inputs horizontally */
    width: 100%; /* Optional: Adjust to ensure it doesn't shrink */
  }
  
  .input-container-register input {
    width: 240px; /* Adjust width to your preference */
    height: 32px;
  }

  .separator-register hr {
    width: 90px;
  }

  .remember-me-register {
    display: flex;
    flex-direction: row;
  }

  .remember-me-register p {
    font-size: 11px !important;
  }

  .RegisterBtn {
    background-color: #b1e5f2;
    color: #272635;
    padding: 10px 15px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 155px;
    border-radius: 10px;
    font-size: 18px;
    /* font-weight: 700; */
    font-family: 'Roboto-Light';
  }

  .separator-register {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .separator-register span{
    font-size: 14px !important;
  }

  .form-register p{
    font-size: 14px;
  }
}
