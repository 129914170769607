.posts {
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: 100px;
  margin-left: 100px;
  gap: 60px;
}

@media (max-width: 768px) {
  .posts {
    gap: 10px;
    padding-top: 0px;
  }
}
