.author-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 50px 0;
}

.author-image {
  width: 30%;
  max-width: 30%;
}

.author-image img {
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.author-bio {
  max-width: 40%;
  text-align: center;
}

.author-bio h2 {
  margin-top: 0;
  font-size: 32px;
  font-weight: 700;
  padding-bottom: 20px;
}

.author-bio p {
  white-space: pre-line;
  text-align: left;
  font-size: 16px;
  text-align: center;
}

.posted-article {
  text-align: center;
  margin-top: 20px;
}

.posted-article-text {
  font-size: 28px;
}

.divider {
  border: 1px solid #272635;
  width: 100%;
}

.single-author-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

.posts {
  width: 100%;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  justify-content: center;
  align-items: center;
}

.postImg {
  width: 100%;
}

@media (max-width: 768px) {
  .author-section {
    margin-bottom: 8.5%;
  }

  .author-bio h2 {
    font-size: 22px;
  }

  .author-bio p {
    font-size: 12px;
    line-height: 1.5;
  }

  .hide-post-elements {
    display: flex;
  }

  .posted-article-text {
    margin-bottom: 20px;
  }

  .post {
    margin-bottom: 10px;
  }

  .postImg {
    height: auto;
    max-height: 275px;
  }
}

@media (max-width: 480px) {
  .author-section {
    flex-direction: column;
  }

  .author-image {
    width: 70%;
    max-width: 70%;
    margin-bottom: 20px;
}

.author-bio {
    max-width: 70%;
    margin-bottom: 20px;
  }

  .author-bio h2 {
    font-size: 22px;
  }

  .author-bio p {
    font-size: 12px;
    line-height: 1.5;
  }

  .posted-article-text {
    margin-bottom: 0px;
  }

  .postImg {
    height: auto;
  }
}
