.top {
  width: 100%;
  height: 50px;
  background-color: white;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  font-family: "Josefin Sans", sans-serif;
  z-index: 999;
}

.topLeft,
.topRight {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topCenter {
  flex: 6;
}

.topIcon {
  font-size: 20px;
  margin-right: 10px;
  color: #444;
  cursor: pointer;
}

.topImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.topList {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.topListItem {
  margin-right: 30px;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
}

.topSearchIcon {
  font-size: 18px;
  color: #666;
  cursor: pointer;
  margin-left: 15px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content2 {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  
}

.dropdown:hover .dropdown-content2 {
  display: block;
}
.listItem{
  text-decoration: none;
}
.catsName{
  /* text-transform: uppercase; */
  font-family: "Josefin Sans", sans-serif;
  margin-bottom: 5px;
}
.logo{
  width: 100%;
  height: 50px;
}
.option{
  color: #272635;
  font-family: "Josefin Sans", sans-serif;
  margin-bottom: 5px;
  font-style: normal;
}