.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the dialog is above other content */
}

.dialog {
  width: 85% !important;
  max-width: 900px !important;
  height: auto !important;
  max-height: 80% !important;
  background-color: #F7F8FB;
  border-radius: 10px;
  position: relative; /* For positioning the close button */
  padding: 20px; /* Padding inside the dialog */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the heading horizontally */
}

.dialog-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
}

.dialog-header h2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 !important;
}

.close-btn {
  margin-left: auto;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  padding: 0 20px;
}

.close-btn:hover {
  color: #f00; /* Red on hover */
}

.dialog-separator {
  width: 100%;
  height: 2px;
  background-color: #272635;
  margin: 20px 0;
}

.dialog-body {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.dialog-text {
  width: 40%;
  margin: 20px;
}

.dialog-text h2 {
  display: none;
  font-size: 24px !important;
  padding: 12px !important;
}

.dialog-text p {
  font-size: 16px;
}

.dialog-image {
  width: 40%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.dialog-image img {
  max-width: 100%;
  max-height: auto;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .dialog-text h2 {
    font-size: 22px !important;
  }

  .dialog-text p {
    font-size: 15px;
  }
}

@media screen and (max-width: 480px) {
  .dialog-header h2 {
    font-size: 22px !important;
  }

  .dialog-separator {
    margin: 10px 0;
  }

  .dialog-body {
    flex-direction: column;
    flex-direction: column-reverse;
    padding: 10px;
  }

  .dialog-text {
    width: 80%;
    margin: 10px;
  }

  .dialog-image {
    width: 60%;
    margin: 10px;
  }

  .dialog-text h2 {
    font-size: 18px !important;
  }

  .dialog-text p {
    font-size: 12px;
  }

  .close-btn {
    font-size: 1rem;
  }
}
