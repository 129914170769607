
.UserProfileForm   {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.UserProfileForm label {
  margin-bottom: 10px;
  align-self: flex-start;
  font-size: 20px;
}

.UserProfileForm input {
  padding-left: 20px;
  margin-bottom: 28px;
  border-radius: 10px;
  width: 747px;
  height: 67px;
  background-color: #C9CDD3;
  font-size: 24px;
  margin-top: 10px;
  border: none;
}

.button {
  padding: 10px 20px;
  color: #b1e5f2;
  background-color: #272635;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 50%;
}

.button:hover {
  background-color: #90cad7;
}

.save-button {
  padding: 10px 35px; /* Adjust padding for smaller size */
  background-color: #272635;
  color: #b1e5f2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #90cad7;
}

/* Side by Side form input */

.form-row {
  display: flex;
  flex-direction: row; /* Display flex items horizontally */
  justify-content: space-between; /* Distribute items evenly along the row */
}

.input-container-UserProfile {
  flex: 0 0 48%; /* Set width of input container */
}

.input-container-UserProfile label {
  display: block; /* Ensure label takes up full width */
  margin-bottom: 5px; /* Adjust margin between label and input */
}

.input-container-UserProfile input {
  width: 50%; /* Ensure input field takes up full width of container */
}

.input-container-UserProfile select {
  width: 50%; /* You can adjust the width of the select dropdown as needed */
  height: 45%;
  border-radius: 10px;
  background-color: #C9CDD3;
  font-size: 24px;
  padding-left: 15px;
}

/* Asterick ** */

.required {
  color: #272635; /* Adjust color as needed */
  margin-left: 5px; /* Adjust spacing between label and asterisk */
}