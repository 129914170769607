.searchForm1 > input{
    color: gray;
    margin: 10px 40px;
    height: 30px;
    border: none;
    border-bottom: 1px solid lightgray;
    width: 80%;
    margin-left: 10%;
    align-items: center;
    
  }
  .searchForm1 {
    display: flex;
    
  }

  .searchForm1 > button{
    height: 25px;
    width: fit-content;
    border: 0;
    outline: 0;
    margin-top: 12px;
    background-color: #ffffff;
    font-size: 20px;
    margin-left: -70px;
  }
  .searchForm1 > button:hover{
   color: rgb(63, 235, 71);
  }
  .searchForm1 > input:focus{
    outline: none;
  }