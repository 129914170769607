@font-face {
  font-family: "Roboto";
  src: url("../../../../public/web\ fonts/roboto_black_macroman/Roboto-Black-webfont.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}
.manage-subscription {
  margin-left: 25px;
}

.horizontal_line {
  width: 102.5%;
  height: 10px;
  border-top: 1px solid #272635;
  line-height: 80%;
  margin-top: 20px;
  margin-left: -10px;
}

.subscription-cards {
  display: flex; /* Create a flex container */
  justify-content: flex-start; /* Align cards from the left */
  gap: 100px; /* Add gap between cards */
  margin-top: 20px;
}
.subscription-card {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  height: 306px;
  width: 312px;
  background-color: #a6a6a8;
  border-radius: 10px;
  cursor: pointer;
}

.button-like-text {
  display: inline-block;
  padding: 6px 12px; /* Adjust padding to control the size of the button */
  background-color: #272635; /* Button-like background color */
  color: #b1e5f2; /* Text color */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto";
  height: 20px;
  width: 60px;
}

.monthly.selected {
  background-color: #b1e5f2; /* Change background color for selected monthly card */
}

.annually.selected {
  background-color: #b1e5f2; /* Change background color for selected annually card */
}

button {
  font-family: "Roboto-Medium";
}

.CancelCheckBox {
  display: flex;
}

.CancelCheckBox input[type="checkbox"] {
  width: 19px;
  height: 19px;
  margin-right: 5px;
  cursor: pointer;
  box-shadow: none;
  border-radius: 5px;
  outline: none;
  appearance: none; /* Removes default checkbox style */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background-color: #c9cdd3; /* Default grey background */
  border: 1px solid #c9cdd3; /* Border matching background color */
  position: relative;
}

.CancelCheckBox input[type="checkbox"]::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 10px;
  border: solid #272635;
  border-width: 0 3px 3px 0;
  transform: translate(-50%, -66%) rotate(45deg) scale(0);
  transition: transform 0.2s ease-in-out;
}

.CancelCheckBox input[type="checkbox"]:checked::after {
  transform: translate(-50%, -50%) rotate(45deg) scale(1);
}

.CancelCheckBox input[type="checkbox"]:checked {
  background-color: #c9cdd3; /* Maintain the same grey background */
  border: 1px solid #c9cdd3; /* Border matching background color */
}
