.page-container-username {
  display: flex;
  height: 100vh;
}

.left-container-username {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background-color: #b1e5f2;
  position: relative;
  border-right: 1px solid #272635;
}

.logo-username {
  width: 350px;
  height: 350px;
  /* opacity: 0.35;  */
  /* position: static; */
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translateY(-95%);
  mix-blend-mode: multiply;
}

.user-form {
  margin-left: 10%;
}

.inputUser {
  width: 537px;
  height: 48px;
  margin: 8px 0;
  display: inline-block;
  background-color: #c9cdd3;
  border-radius: 10px;
  border: none;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 400;
  outline: none;
}

.username-button {
  background-color: #b1e5f2;
  color: #272635;
  padding: 15px 20px; /* Maintain original button size */
  margin: 8px 0;
  border: none;
  cursor: pointer;
}

.username-button.inactive {
  background-color: #a6a6a8;
  color: #e8e9f3;
  cursor: not-allowed;
}

.username-button.active {
  background-color: #272635;
  color: #b1e5f2;
  cursor: pointer;
}

.username-button:hover {
  opacity: 0.8;
}

.username-next-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.next-text {
  font-size: 20px;
  font-weight: 400;
  margin-left: 523px;
  font-family: Roboto;
}

.right-container-username {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: center;  */
  align-items: center;
  /* position: relative; */
  /* background-color: #ffffff; Set background color to differentiate from the first page */
  /* background-color: #E8E9F3; */
  z-index: 0;
}

.picture-username {
  width: 100%; /* Make the picture fill the container width */
  height: 35%; /* Maintain aspect ratio */
  margin-top: auto;
  border-top: 1px solid #272635;
}

/* --------------------------------------------------------- */

.container-username {
  width: 700px;
  padding-left: 100px;
  padding-bottom: 150px;
  padding-top: 210px;
}
.progressbar-username li {
  list-style-type: none;
  width: calc(25% - 30px);
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  color: #7d7d7d;
}
.progressbar-username li:before {
  width: 39px;
  height: 39px;
  content: "";
  line-height: 35px;
  display: block;
  /* text-align: top; */
  margin: 0 auto 10px auto;
  border-radius: 50%;
}
.progressbar-username li.done:before {
  content: "✓";
  color: #272635;
  font-size: 30px;
}

.progressbar-username li span {
  position: absolute;
  color: #272635;
  top: -30px; /* Adjust vertical position */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  font-size: 12px;
  font-weight: 400;
}

.progressbar-username li:after {
  width: 100%;
  height: 3px;
  content: "";
  position: absolute;
  background-color: #c9cdd3;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar-username li:first-child:after {
  content: none;
}
.progressbar-username li.active {
  color: rgb(0, 0, 0);
}
.progressbar-username li.active:before {
  background-color: #a6a6a8;
  border: 3px solid #b1e5f2;
}
.progressbar-username li.active + li:after {
  background-color: #b1e5f2;
}
.progressbar-username li.done:before {
  border-color: #272635;
  background-color: #b1e5f2;
}
.progressbar-username li.done + li:after {
  background-color: #a6a6a8;
}
.progressbar-username li.inactive:before {
  border-color: #7d7d7d;
  background-color: #a6a6a8;
}
.progressbar-username li.inactive + li:after {
  background-color: #b1e5f2;
}

/* Define circular button styles */
.next-button-username {
  top: 650px;
  margin-left: 523px;
  width: 60px;
  height: 60px;
  background-color: #272635;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b1e5f2;
  font-size: 45px;
  text-decoration: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 80px;
}

/* Media queries for smaller screens */
@media (max-width: 1200px) {
  .logo-username {
    width: 300px;
    height: 300px;
    left: 20%;
  }

  .separator-username hr {
    width: 180px;
  }

  .separator-username span {
    font-size: 30px;
  }

  /* Define circular button styles */
  .next-button-username {
    width: 60px;
    height: 60px;
    background-color: #272635;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #b1e5f2;
    font-size: 45px;
    text-decoration: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 80px;
    /* Remove position: sticky and margin-left */
  }

  /* Ensure text and button are aligned vertically */
  .next-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px; /* Optional: Adjust margin for spacing */
    margin-left: 85%;
  }

  .next-container p {
    margin-top: 10px; /* Add some space between the button and the text */
    font-size: 20px;
    color: #272635;
    font-weight: 400;
  }

  /* Container that holds the input field and the checkmark */

  /* Checkmark icon */
  .checkmark-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #272635;
    font-size: 30px;
    opacity: 0;
    animation: fadeIn 0.3s forwards; /* Animation when it appears */
  }

  /* Animation for the checkmark */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.5) translateY(-50%);
    }
    to {
      opacity: 1;
      transform: scale(1) translateY(-50%);
    }
  }

  /* Media queries for smaller screens */
  @media (max-width: 1200px) {
    .logo-username {
      width: 300px;
      height: 300px;
      left: 20%;
    }

    .separator-username hr {
      width: 180px;
    }

    .separator-username span {
      font-size: 30px;
    }

    .form-username {
      text-align: center;
      max-width: 400px;
      margin-top: 100px;
    }
  }
}
