/* Default styles (for desktop and larger screens) */
.header-title-authors {
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  flex-grow: 1;
  text-align: center;
  margin-top: -5%;
}

.card-container-authorInfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  gap: 40px; /* Add a gap between the cards */
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 20px;
}

.card-authorInfo {
  width: 375px;
  height: 273px;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.image-container-authorInfo {
  position: relative;
  width: 100%;
  height: 100%;
}

.card-authorInfo img {
  width: 100%;
  height: auto;
  display: block;
}

.caption-authorInfo {
  position: absolute;
  bottom: -1px;
  width: 100%;
  height: 22%;
  background-color: rgba(39, 38, 53, 0.55); /* Semi-transparent background */
  color: white; /* Text color */
  text-align: center;
  padding: 8px;
  box-sizing: border-box;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center; /* Centers text vertically */
  justify-content: center; /* Centers text horizontally */
}

.card-content-authorInfo {
  padding: 10px;
}

.card-content-authorInfo h2 {
  font-size: 18px;
  margin: 0 0 10px 0;
}

.card-content-authorInfo p {
  font-size: 14px;
  color: #666;
}

@media screen and (max-width: 768px) {
  .header-title-authors {
    font-size: 32px;
  }

  .card-container-authorInfo {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
    margin-right: 10px;
    gap: 10px;
  }

  .card-authorInfo {
    width: 45%;
    height: auto;
    border: none;
  }

  .image-container-authorInfo {
    width: 100%;
    height: auto;
  }

  .caption-authorInfo {
    font-size: 20px;
    padding: 8px;
    position: absolute;
    height: 20%;
    text-align: center;
    display: flex;
    align-items: center; /* Centers text vertically */
    justify-content: center; /* Centers text horizontally */
  }

  .pagination {
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  .pagination-left-btn,
  .pagination-right-btn {
    width: 40px;
    height: 25px;
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .header-title-authors {
    font-size: 28px;
    margin-top: 20px;
  }

  .caption-authorInfo {
    font-size: 12px;
    padding: 5px;
    position: absolute;
    height: 35%;
    text-align: center;
    display: flex;
    align-items: center; /* Centers text vertically */
    justify-content: center; /* Centers text horizontally */
  }
}
