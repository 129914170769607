.post {
  width: 428px;
  margin-bottom: 40px;
}

.post p {
  font-size: 15px;
  font-weight: 400;
  color: #272635;
}

.postImg {
  width: 415px;
  height: 302px;
  object-fit: cover;
  border-radius: 10px;
}

.postInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.postCat {
  font-size: 11px;
  color: #272635;
  line-height: 20px;
  margin-top: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.postTitleContainer {
  height: 75px; /* Adjust this height based on your design requirements */
  display: flex;
  align-items: center;
}

.postTitle {
  font-size: 25px;
  font-weight: 500;
  margin-top: 15px;
  cursor: pointer;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  color:#272635;
  text-align: center;
}
.postDetails {
  align-items: center;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.authorImg {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 30px;
}

.authorName-Post {
  font-size: 15px;
  font-weight: 400;
  margin-right: 30px;
  color: #272635;
}
.postDate {
  font-size: 15px;
  font-weight: 400;
  color: #272635;
  align-items: flex-end;
}

.postDesc {
  font-family: "Varela Round", sans-serif;
  font-size: 14px;
  color: #444;
  line-height: 24px;
  margin-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.readMoreButton {
  width: 201px;
  height: 39px;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  color: #272635;
  background-color: #b1e5f2;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .readMoreButton:hover {
  background-color: #0056b3;
} */

@media (max-width: 768px) {
  .post {
    margin-bottom: 0px;
  }
  .hide-post-elements .postDetails,
  .hide-post-elements .postDesc,
  .hide-post-elements .readMoreButton {
    display: none;
  }
  .postDesc p {
    font-size: 10px;
    line-height: normal;
  }
  .postCats {
    display: none;
  }
  .postImg {
    width: 358px;
    height: 114px;
    object-fit: cover;
    border-radius: 10px;
  }
  .postTitleContainer {
    height: auto;
    margin-bottom: 5px;
  }
  .postTitle {
    margin-top: 0;
    font-size: 14px;
  }
  .authorImg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .authorName-Post {
    font-size: 8px;
    margin-right: 200px;
  }
  .postDate {
    font-size: 8px;
  }
  .readMoreButton {
    font-size: 12px;
    font-weight: 600;
    width: fit-content;
    height: 24px;
    padding: 4px;
  }
  .postDetails {
    margin-top: 0;
  }
}
