.forgot-button-container {
    display: flex;
    gap: 10px;
}

.forgot-btn {
background-color: #b1e5f2;
  color: #272635;
  padding: 15px 20px; 
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 289px;
  border-radius: 10px;
  font-size: 25px;
}