.overlay-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(39, 38, 53, 0.7); /* Gray color with 70% opacity */
  z-index: 5; /* Below the popup, but above the rest of the content */
  display: none; /* Hidden by default */
}

/* Show the overlay and popup when they are active */
.popup:not(.hidden),
.overlay-popup:not(.hidden) {
  display: block;
}

.popup {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 40%;
    height: 507px; /* Adjust height as needed */
    background-color: #F7F8FB; /* Semi-transparent background */
    transition: bottom 0.3s ease-in-out; /* Animation for sliding up */
    z-index: 10; /* Ensure popup is above other content */
  }
  
  .popup.hidden {
    bottom: -100%; /* Hide the popup initially */
  }
  
  .popup-content {
    
    
  }
  
  .popup-image {
    height: 210px;
    width: 100%; /* Adjust image width as needed */
    object-fit: cover; /* Maintain image aspect ratio */
    border-radius: 5px;
  }
  
  .popup-text {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center text and logo horizontally */
    text-align: center; /* Center text content */
  }
  
  .popup-logo {
    width: 220px; /* Adjust logo width as needed */
    height: 126px;
    margin-bottom: 1rem;
  }
  
  .close-popup:hover {
    color: #f00; /* Red on hover */
  }
  
  .button-pop3 {
    background-color: #272635; /* Dark gray for button background */
    color: #b1e5f2; /* White text for button */
    padding: 0.75rem 1rem;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out; /* Smooth hover effect */
    margin-top: 10px;
    width: 372px;
  }
  
  .separator-pop3 {
    display: flex;
    align-items: center;
    margin: 10px 10px; /* Adjust spacing between buttons and separator */
    margin-top: 40px;
  }
  
  .separator-pop3 hr {
    width: 241px; /* Adjust width of the lines */
    height: 1px; /* Adjust height of the lines */
    border: none; /* Remove default border style */
    background-color: #272635; /* Color of the lines */
    margin: 0 5px; /* Spacing between lines and "OR" text */
  }
  
  .separator-pop3 span {
    margin: 0 auto; /* Center "OR" text horizontally */
  }