.container-SignUpPopUp {
    position: relative;
    height: calc(100vh - 424px); /* Adjust the height to be viewport height minus popup height */
    overflow-y: calc(100vh - 124px); /* Enable vertical scrolling */
  }
  
  .popup-overlay-SignUpPopUp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(39, 38, 53, 0.7); /* Semi-transparent background */
    z-index: 999; /* Lower than the popup but higher than the rest of the content */
  }
  
  .popup-SignUpPopUp {
    position: fixed;
    bottom: 0; /* Adjust this to position the popup at the bottom of the viewport if needed */
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden; /* Prevent scroll within the popup */
    border-radius: 10px 10px 0px 0px;
  }
  
  .border-content-SignUpPopUp {
    border-top: #272635 1px solid;
    border-bottom: #272635 1px solid;
    border-radius: 10px 10px 0 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .popup-content-SignUpPopUp {
    background: #F7F8FB;
    text-align: center;
    border-radius: 5px;
    width: 100%;
  }
  
  .popup-content-SignUpPopUp p:first-child {
    font-size: 18px;
    font-weight: bold;
  }
  
  .popup-content-SignUpPopUp p:nth-child(2) {
    margin-top: 10px;
  }
  
  .popup-content-SignUpPopUp button {
    margin-top: 20px;
    background: #272635;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 372px;
    height: 72px;
    font-size: 28px;
    color: #B1E5F2;
  }
  
  .popup-content-SignUpPopUp p {
    margin-top: 20px;
  }
  
  .popup-content-SignUpPopUp a {
    color: #272635;
    text-decoration: none;
  }
  
  .popup-content-SignUpPopUp a:hover {
    text-decoration: underline;
  }