.header-title-categories {
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  flex-grow: 1;
  text-align: center;
  margin-top: -5%;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 20px;
  gap: 40px; /* Add a gap between the cards */
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 20px;
}

.card-categories {
  width: 396px;
  height: 348px;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-evenly;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.card-categories img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.caption-categories {
  position: absolute;
  bottom: -1px;
  width: 100%;
  height: 22%;
  background-color: rgba(39, 38, 53, 0.55); /* Semi-transparent background */
  color: white; /* Text color */
  text-align: center;
  padding: 8px;
  box-sizing: border-box;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center; /* Centers text vertically */
  justify-content: center; /* Centers text horizontally */
}

.card-content {
  padding: 10px;
}

.card-content h2 {
  font-size: 18px;
  margin: 0 0 10px 0;
}

.card-content p {
  font-size: 14px;
  color: #666;
}

@media (max-width: 768px) {
  .header-title-categories {
    font-size: 32px;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
    margin-right: 10px;
    gap: 10px;
  }

  .card-categories {
    width: 45%;
    height: 123px;
    border: none;
  }

  .image-container {
    width: 100%;
    height: 100%;
  }

  .caption-categories {
    font-size: 10px;
    padding: 8px;
  }
  .card-content {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .header-title-categories {
    font-size: 28px;
    margin-top: 20px;
  }
}
