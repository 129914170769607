.center-text {
  text-align: center;
}

.center-text h1 {
  margin-bottom: 8px;
  margin-top: -80px;
}

.center-text p {
  margin-bottom: 30px !important;
}

.card-container-subscribe {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.card-subscribe {
  margin: 20px;
  width: 40%;
  max-width: 531px;
  aspect-ratio: 1.36 / 1;
  background-color: #a6a6a8;
  border-radius: 10px;
  padding: 30px;
}

.card-subscribe button {
  margin: 0 auto;
  background-color: #b1e5f2;
  color: #272635;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.card-free-months {
  font-size: 22px !important;
  height: 50px !important;
  right: -50px !important;
}

@media screen and (max-width: 768px) {
  .center-text h1 {
    font-size: 32px !important;
  }

  .center-text p {
    font-size: 24px !important;
    margin-bottom: 0px !important;
  }

  .card-subscribe h2 {
    font-size: 22px !important;
  }

  .card-container-subscribe {
    flex-direction: column;
  }

  .card-subscribe {
    width: 60%;
  }
}

@media screen and (max-width: 480px) {
  .center-text h1 {
    font-size: 22px !important;
    margin-top: 0px;
  }

  .center-text p {
    font-size: 19px !important;
    margin-bottom: 0px !important;
  }

  .card-subscribe h2 {
    font-size: 18px !important;
  }

  .card-container-subscribe {
    flex-direction: column;
  }

  .card-subscribe {
    width: 80%;
  }

  .card-free-months {
    font-size: 18px !important;
    width: 170px !important;
    height: 45px !important;
    right: -25px !important;
  }

  .card-subscribe button {
    width: 200px !important;
    height: 50px !important;
    font-size: 22px !important;
  }
}
