.settings {
  display: flex;
  width: 100%;
  padding-left: 5%;
  
}
.update-info{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: 5%;
}
.update-cat{
  display: flex;
  flex-wrap: wrap;
}
.settingsWrapper {
  flex: 9;
  padding: 20px;
}

.settingsTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settingsUpdateTitle {
  font-size: 30px;
  margin-bottom: 20px;
  color: lightcoral;
}

.settingsDeleteTitle {
  color: red;
  font-size: 12px;
  cursor: pointer;
  margin-right: 100px;
}

.update-info {
  display: flex;
  flex-direction: column;
}

.settingsPP {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.settingsPP > img {
  width: 70px;
  height: 70px;
  border-radius: 20px;
  object-fit: cover;
}

.settingsPPIcon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: lightcoral;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}

.update-info > label {
  font-size: 20px;
  margin-top: 20px;
}

.update-info > input {
  color: gray;
  margin: 10px 0;
  height: 30px;
  border: none;
  border-bottom: 1px solid lightgray;
}

.settingsSubmit{
    width: 150px;
    align-self: center;
    border: none;
    border-radius: 10px;
    color: white;
    background-color: teal;
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;
    margin-left: 75%;
}
.current-interests{
  display: flex;

}
.current-interests > p{
  margin-left: 10px;
}
.bio{
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}