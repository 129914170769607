.footer{
    text-align: center;
    color: #272635;
    background-color: #B1E5F2;
    padding-top: 20px;
}
.footer-content{
    /* display: flex; */
    /* flex-wrap: wrap; */
    justify-content: center;
}

.footer-content a{
    content: "|";
    margin-left: 5px;
}

.pipe{
    margin-left: 15px;
    font-size: 40px;
}
.centerItems{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;

}

.centerItems a {
    margin: 0 10px;
    text-decoration: none;
    color: #272635;
}

.copyright{
    margin-top: 20px;
}
.footer-item{
    flex: 4;
}

/* Contact US Dialog Box */

.contact-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .contact-dialog-content {
    text-align: center;
  }
  
  .contact-dialog button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contact-dialog button:hover {
    background-color: #0056b3;
  }